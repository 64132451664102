import { styled } from "@linaria/react";

const StyledDiv = styled.div`
    padding: var(--space-s);
`;

function ThankYou() {
  return (
    <StyledDiv>
      <h2>THANK YOU!</h2>
      <h3>Getting things done and stop procrastinating for good will be delivered to your email soon!</h3>
      <p>If you don&apos;t see the guide, make sure to check your spam or junk folder!</p>
    </StyledDiv>
  );
}

export default ThankYou;
