import {
  Routes,
  Route,
  useLocation
} from "react-router-dom";
import { css } from "@linaria/core";
import ReactGA from "react-ga4";

// Pages
import {
  lazy, Suspense, useEffect, useRef, useState
} from "react";
import ThankYou from "../../pages/thankyou/ThankYou";
import Navbar from "./components/Navigation/Navbar";
import Footer from "./components/Footer/Footer";
import contact from "../../../constants/companyData.json";

// Components
// import Title from "./components/Title";
const Disclaimer = lazy(() => import("../../pages/disclaimer/Disclaimer"));
const Privacy = lazy(() => import("../../pages/privacy/Privacy"));
const Terms = lazy(() => import("../../pages/terms/Terms"));
const About = lazy(() => import("../../pages/about/About"));
const Home = lazy(() => import("../../pages/home/Home"));
const Services = lazy(() => import("../../pages/services/Services"));

const Content = css`
  overflow-y: scroll;
`;

function UseGA() {
  const location = useLocation();
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    // if (!window.location.href.includes("localhost")) {
    ReactGA.initialize(`${process.env.REACT_APP_GANALYTICS}`);
    setInitialized(true);
    // }
  }, []);

  useEffect(() => {
    if (initialized) {
      ReactGA.send({ hitType: "pageview", page: `${location.pathname}${location.search}` });
    }
  }, [initialized, location]);
}

function ScrollToTop({ children, className }) {
  const location = useLocation();
  const ref = useRef();
  useEffect(() => {
    ref.current.scrollTo(0, 0);
    const { hash } = location;
    if (hash) {
      const id = hash.replace("#", "");
      const element = document.getElementById(id);
      if (element) element.scrollIntoView({ block: "start", behavior: "smooth" });
    }
  }, [location]);

  return <div ref={ref} className={className}>{children}</div>;
}

function RouteWithTitle({ title, children }) {
  useEffect(() => {
    document.title = `Reveal You - ${title}`;
  });
  return children;
}

export default function Layout() {
  UseGA();
  return (
    <>
      <Navbar />
      <ScrollToTop className={Content}>
        <Routes>
          <Route
            path="/disclaimer"
            element={(
              <RouteWithTitle title="Disclaimer">
                <Suspense fallback={<div>Loading...</div>}>
                  <Disclaimer
                    companyName={contact.companyName}
                    emailAddress={contact.emailAddress}
                    street={contact.street}
                    city={contact.city}
                    state={contact.state}
                    zip={contact.zip}
                    revisionDate={contact.revisionDate}
                  />
                </Suspense>
              </RouteWithTitle>
            )}
          />

          <Route
            path="/privacy"
            element={(
              <RouteWithTitle title="Privacy Policy">
                <Suspense fallback={<div>Loading...</div>}>
                  <Privacy
                    companyName={contact.companyName}
                    emailAddress={contact.emailAddress}
                    street={contact.street}
                    city={contact.city}
                    state={contact.state}
                    zip={contact.zip}
                    revisionDate={contact.revisionDate}
                  />
                </Suspense>
              </RouteWithTitle>
            )}
          />

          <Route
            path="/terms"
            element={(
              <RouteWithTitle title="Terms of Use">
                <Suspense fallback={<div>Loading...</div>}>
                  <Terms
                    companyName={contact.companyName}
                    emailAddress={contact.emailAddress}
                    street={contact.street}
                    city={contact.city}
                    state={contact.state}
                    zip={contact.zip}
                    revisionDate={contact.revisionDate}
                  />
                </Suspense>
              </RouteWithTitle>
            )}
          />

          <Route
            path="/services"
            element={(
              <RouteWithTitle title="Services">
                <Suspense fallback={<div>Loading...</div>}>
                  <Services />
                </Suspense>
              </RouteWithTitle>
            )}
          />
          <Route
            path="/about"
            element={(
              <RouteWithTitle title="About us">
                <Suspense fallback={<div>Loading...</div>}>
                  <About />
                </Suspense>
              </RouteWithTitle>
            )}
          />
          <Route
            path="/thank-you"
            element={(
              <RouteWithTitle title="Thank you">
                <ThankYou />
              </RouteWithTitle>
            )}
          />
          <Route
            path="/"
            element={(
              <RouteWithTitle title="Landing">
                <Suspense fallback={<div>Loading...</div>}>
                  <Home />
                </Suspense>
              </RouteWithTitle>
            )}
          />
        </Routes>
      </ScrollToTop>
      <Footer />
    </>
  );
}
