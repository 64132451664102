import { styled } from "@linaria/react";
import { css } from "@linaria/core";
import { useState } from "react";
import { NavLink } from "react-router-dom";
import { Typography } from "../../../../../constants/styles/global";
import logo from "../../../../../Images/PeacockNew.svg";

const StyledNavbar = styled.div`
    background-color: var(--primary-color);
    box-shadow: 0 0 4px var(--secondary-color);
    z-index: 2;
`;

const StyledLi = styled.li`
    color: inherit;

    &+&::before{
        content:'';
        width: 40px;
        height: 1px;
        background: var(--primary-color);
        margin: var(--space-m) auto;
        display: block;
    }
`;

const StyledNavLink = styled(NavLink)`
    color: inherit;
    font-family: '${Typography.name}';
    font-weight: 400;
    font-size: var(--step-0);
    text-decoration: none;

    :hover {
        filter: brightness(${1 - 0.2});
    }
`;

const StyledUl = styled.ul`
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    list-style: none;
    /* gap: var(--space-2xl); */
    width: 100vw;
    height: 100vh;
    color: var(--primary-color);
    margin: 0;
    padding: 0;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    background-color: var(--secondary-color);
    transition: opacity 0.3s,
                visibility 0.3s;
    opacity: 0;
    visibility: hidden;
    z-index: 99;

    &[data-open="true"] {
        opacity: 1;
        visibility: visible;
    }
`;

function MenuList({ children, open, notifyMenuToggle }) {
  return <StyledUl onClick={() => notifyMenuToggle()} aria-hidden="true" data-open={open}>{children}</StyledUl>;
}

const StyledCoaching = styled.span`
    font-family: '${Typography.cursive}';
    font-size: var(--step-1);
    margin-right: var(--space-xs);
`;

const StyledName = styled.span`
    font-family: '${Typography.name}';
    font-size: var(--step-0);
    font-weight: 100;
`;

const StyledBy = styled(StyledName)`
    margin-right: var(--space-xs);
`;

const StyledLogoWrapper = styled(NavLink)`
  display: flex;
  flex-direction: row;
  color: inherit;
  text-decoration: none;
  align-items: center;
  gap: var(--space-s);
  width: 100%;
`;

const StyledTag = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
`;

const StyledNav = styled.nav`
    margin: var(--space-2xs) var(--space-s);
`;

const StyledInnerNav = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;

    max-width: 67.5rem;
    height: 100%;
    margin: 0 auto;
`;

const MenuLine = css`
    i+i {
        margin-top: var(--menu-icon-space);
    }

    border: none;
    background: none;
    z-index:100;
    cursor: pointer;

    :hover{
        i {
            filter: brightness(3);
        }

        i[data-open="true"] {
            filter: brightness(0.6);
        }
    }
`;

const StyledLogo = styled.img`
  max-height: 2rem;
`;

const StyledMenuLine = styled.i`
    display: block;
    width: var(--menu-icon-width);
    height: var(--menu-icon-height);
    background-color: var(--secondary-color);

    &[data-open="true"] {
        background-color: var(--primary-color);
    }
`;

function MenuButton({ notifyToggle, open }) {
  return (
    <button type="button" className={MenuLine} onClick={() => notifyToggle()}>
      <StyledMenuLine data-open={open} />
      <StyledMenuLine data-open={open} />
      <StyledMenuLine data-open={open} />
    </button>
  );
}

function Navbar() {
  const [menuOpen, setMenuOpen] = useState(false);
  const notifyMenuToggle = () => {
    setMenuOpen(!menuOpen);
  };
  return (
    <StyledNavbar>
      <StyledNav>
        <StyledInnerNav>
          <StyledLogoWrapper to="/">
            <StyledLogo src={logo} alt="Reveal You Logo" />
            <StyledTag>
              <StyledCoaching>Coaching</StyledCoaching>
              <StyledBy>by Kirthi Lilley, MD</StyledBy>
            </StyledTag>
          </StyledLogoWrapper>
          <MenuButton open={menuOpen} notifyToggle={notifyMenuToggle} />
        </StyledInnerNav>
        <MenuList
          notifyMenuToggle={notifyMenuToggle}
          open={menuOpen}
        >
          <StyledLi><StyledNavLink to="/">Home</StyledNavLink></StyledLi>
          <StyledLi><StyledNavLink to="/services">Services</StyledNavLink></StyledLi>
          <StyledLi><StyledNavLink to="/about">About</StyledNavLink></StyledLi>
          {/* <StyledLi><StyledNavLink to="/free">Free Stuff</StyledNavLink></StyledLi> */}
          {/* <StyledLi><StyledNavLink to="/contact">Contact Me</StyledNavLink></StyledLi> */}
        </MenuList>
      </StyledNav>
    </StyledNavbar>
  );
}

export default Navbar;
