import { styled } from "@linaria/react";
import { NavLink } from "react-router-dom";

import {
  faCircle
} from "@fortawesome/free-solid-svg-icons";
import { faInstagram, faFacebook } from "@fortawesome/free-brands-svg-icons";
import { } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const StyledWrapper = styled.div`
    
    padding: var(--space-s);
    background: var(--secondary-color);
    color: var(--primary-color);
    width: 100%;
`;

const StyledNavLink = styled(NavLink)`
  color: var(--primary-color);
  text-decoration: none;
  :hover{
    color: var(--primary-color-dark);
  }
`;
const StyledAnchor = styled.a`
  font-size: var(--step-1);
  color: var(--primary-color);
  text-decoration: none;
  :hover{
    color: var(--primary-color-dark);
  }
`;

const StyledOutterFooter = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;

  gap: var(--space-s);
  max-width: 67.5rem;
  height: 100%;
  margin: 0 auto;
`;

const StyledInnerFooter = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

const DarkFontAwesome = styled(FontAwesomeIcon)`
  color: var(--secondary-color);
`;

function Footer() {
  return (
    <StyledWrapper>
      <StyledOutterFooter>
        <StyledAnchor href="https://instagram.com/revealyoulifecoaching" aria-label="instagram">
          <span className="fa-layers">
            <FontAwesomeIcon icon={faCircle} />
            <DarkFontAwesome icon={faInstagram} transform="shrink-3 right-0.5" />
          </span>
          {/* <FontAwesomeIcon icon={faCircle} mask={faInstagram} /> */}
        </StyledAnchor>
        <StyledAnchor href="https://www.facebook.com/revealyoulifecoaching" aria-label="instagram"><FontAwesomeIcon icon={faFacebook} /></StyledAnchor>
        <StyledInnerFooter>
          <StyledNavLink to="/privacy">Privacy</StyledNavLink>
          <StyledNavLink to="/terms">Terms of use</StyledNavLink>
          <StyledNavLink to="/disclaimer">Disclaimer</StyledNavLink>
        </StyledInnerFooter>
      </StyledOutterFooter>
    </StyledWrapper>
  );
}

export default Footer;
