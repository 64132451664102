import { css } from "@linaria/core";
import { darken, lighten } from "polished";

export const primaryColorParts = "240, 240, 240";
const primaryColor = `rgba(${primaryColorParts}, 1.0)`;
// export const secondaryColorParts = "23, 47, 153";
export const secondaryColorParts = "50, 50, 50";
const secondaryColor = `rgba(${secondaryColorParts}, 1.0)`;

export const globals = css`
  :global() {
    :root {
      /* @link https://utopia.fyi/type/calculator?c=320,17,1.2,1500,20,1.333,5,2,&s=0.75|0.5|0.25,1.5|2|3|4|6,s-l */

      :root {
        --fluid-min-width: 320;
        --fluid-max-width: 1500;

        --fluid-screen: 100vw;
        --fluid-bp: calc(
          (var(--fluid-screen) - var(--fluid-min-width) / 16 * 1rem) /
            (var(--fluid-max-width) - var(--fluid-min-width))
        );
      }

      @media screen and (min-width: 1500px) {
        :root {
          --fluid-screen: calc(var(--fluid-max-width) * 1px);
        }
      }

      :root {
        --f--2-min: 11.81;
        --f--2-max: 11.26;
        --step--2: calc(
          ((var(--f--2-min) / 16) * 1rem) + (var(--f--2-max) - var(--f--2-min)) *
            var(--fluid-bp)
        );

        --f--1-min: 14.17;
        --f--1-max: 15;
        --step--1: calc(
          ((var(--f--1-min) / 16) * 1rem) + (var(--f--1-max) - var(--f--1-min)) *
            var(--fluid-bp)
        );

        --f-0-min: 17;
        --f-0-max: 20;
        --step-0: calc(
          ((var(--f-0-min) / 16) * 1rem) + (var(--f-0-max) - var(--f-0-min)) *
            var(--fluid-bp)
        );

        --f-1-min: 20.4;
        --f-1-max: 26.66;
        --step-1: calc(
          ((var(--f-1-min) / 16) * 1rem) + (var(--f-1-max) - var(--f-1-min)) *
            var(--fluid-bp)
        );

        --f-2-min: 24.48;
        --f-2-max: 35.54;
        --step-2: calc(
          ((var(--f-2-min) / 16) * 1rem) + (var(--f-2-max) - var(--f-2-min)) *
            var(--fluid-bp)
        );

        --f-3-min: 29.38;
        --f-3-max: 47.37;
        --step-3: calc(
          ((var(--f-3-min) / 16) * 1rem) + (var(--f-3-max) - var(--f-3-min)) *
            var(--fluid-bp)
        );

        --f-4-min: 35.25;
        --f-4-max: 63.15;
        --step-4: calc(
          ((var(--f-4-min) / 16) * 1rem) + (var(--f-4-max) - var(--f-4-min)) *
            var(--fluid-bp)
        );

        --f-5-min: 42.3;
        --f-5-max: 84.17;
        --step-5: calc(
          ((var(--f-5-min) / 16) * 1rem) + (var(--f-5-max) - var(--f-5-min)) *
            var(--fluid-bp)
        );
      }
      --f-0-min: 16;
      --f-0-max: 16;
      --fc-3xs-min: (var(--fc-s-min) * 0.25);
      --fc-3xs-max: (var(--fc-s-max) * 0.25);
      --fc-2xs-min: (var(--fc-s-min) * 0.5);
      --fc-2xs-max: (var(--fc-s-max) * 0.5);
      --fc-xs-min: (var(--fc-s-min) * 0.75);
      --fc-xs-max: (var(--fc-s-max) * 0.75);
      --fc-s-min: (var(--f-0-min));
      --fc-s-max: (var(--f-0-max));
      --fc-m-min: (var(--fc-s-min) * 1.5);
      --fc-m-max: (var(--fc-s-max) * 1.5);
      --fc-l-min: (var(--fc-s-min) * 2);
      --fc-l-max: (var(--fc-s-max) * 2);
      --fc-xl-min: (var(--fc-s-min) * 3);
      --fc-xl-max: (var(--fc-s-max) * 3);
      --fc-2xl-min: (var(--fc-s-min) * 4);
      --fc-2xl-max: (var(--fc-s-max) * 4);
      --fc-3xl-min: (var(--fc-s-min) * 6);
      --fc-3xl-max: (var(--fc-s-max) * 6);
      /* T-shirt sizes */
      --space-3xs: calc(
        ((var(--fc-3xs-min) / 16) * 1rem) +
          (var(--fc-3xs-max) - var(--fc-3xs-min)) * var(--fluid-bp)
      );
      --space-2xs: calc(
        ((var(--fc-2xs-min) / 16) * 1rem) +
          (var(--fc-2xs-max) - var(--fc-2xs-min)) * var(--fluid-bp)
      );
      --space-xs: calc(
        ((var(--fc-xs-min) / 16) * 1rem) + (var(--fc-xs-max) - var(--fc-xs-min)) *
          var(--fluid-bp)
      );
      --space-s: calc(
        ((var(--fc-s-min) / 16) * 1rem) + (var(--fc-s-max) - var(--fc-s-min)) *
          var(--fluid-bp)
      );
      --space-m: calc(
        ((var(--fc-m-min) / 16) * 1rem) + (var(--fc-m-max) - var(--fc-m-min)) *
          var(--fluid-bp)
      );
      --space-l: calc(
        ((var(--fc-l-min) / 16) * 1rem) + (var(--fc-l-max) - var(--fc-l-min)) *
          var(--fluid-bp)
      );
      --space-xl: calc(
        ((var(--fc-xl-min) / 16) * 1rem) + (var(--fc-xl-max) - var(--fc-xl-min)) *
          var(--fluid-bp)
      );
      --space-2xl: calc(
        ((var(--fc-2xl-min) / 16) * 1rem) +
          (var(--fc-2xl-max) - var(--fc-2xl-min)) * var(--fluid-bp)
      );
      --space-3xl: calc(
        ((var(--fc-3xl-min) / 16) * 1rem) +
          (var(--fc-3xl-max) - var(--fc-3xl-min)) * var(--fluid-bp)
      );

      /* One-up pairs */
      --space-3xs-2xs: calc(
        ((var(--fc-3xs-min) / 16) * 1rem) +
          (var(--fc-2xs-max) - var(--fc-3xs-min)) * var(--fluid-bp)
      );
      --space-2xs-xs: calc(
        ((var(--fc-2xs-min) / 16) * 1rem) +
          (var(--fc-xs-max) - var(--fc-2xs-min)) * var(--fluid-bp)
      );
      --space-xs-s: calc(
        ((var(--fc-xs-min) / 16) * 1rem) + (var(--fc-s-max) - var(--fc-xs-min)) *
          var(--fluid-bp)
      );
      --space-s-m: calc(
        ((var(--fc-s-min) / 16) * 1rem) + (var(--fc-m-max) - var(--fc-s-min)) *
          var(--fluid-bp)
      );
      --space-m-l: calc(
        ((var(--fc-m-min) / 16) * 1rem) + (var(--fc-l-max) - var(--fc-m-min)) *
          var(--fluid-bp)
      );
      --space-l-xl: calc(
        ((var(--fc-l-min) / 16) * 1rem) + (var(--fc-xl-max) - var(--fc-l-min)) *
          var(--fluid-bp)
      );
      --space-xl-2xl: calc(
        ((var(--fc-xl-min) / 16) * 1rem) +
          (var(--fc-2xl-max) - var(--fc-xl-min)) * var(--fluid-bp)
      );
      --space-2xl-3xl: calc(
        ((var(--fc-2xl-min) / 16) * 1rem) +
          (var(--fc-3xl-max) - var(--fc-2xl-min)) * var(--fluid-bp)
      );
    }

    :root {
      --primary-color: ${primaryColor};
      --primary-color-dark: ${darken(0.5, primaryColor)};
      --primary-color-light: ${lighten(0.2, primaryColor)};
      /* --secondary-color-parts: 50, 50, 50; Gray */
      /* --secondary-color-parts: 28, 64, 163; /* light blue */
      /*--secondary-color-parts: 23, 47, 153; Dark Blue*/
      --secondary-color: ${secondaryColor};
      --secondary-color-dark: ${darken(0.2, secondaryColor)};
      --secondary-color-light: ${lighten(0.78, secondaryColor)};
      --menu-icon-space: var(--space-2xs);
      --menu-icon-width: var(--step-2);
      --menu-icon-height: 2px;
      color: var(--secondary-color);
      background: var(--primary-color);
    }

    html {
      box-sizing: border-box;
      scroll-behavior: smooth;
    }

    body {
      overflow: hidden;
      font-size: var(--step-0);
    }
    h1 {
      font-size: var(--step-3);
    }
    h2 {
      font-size: var(--step-2);
    }
    h3 {
      font-size: var(--step-1);
    }
    h4 {
      font-size: var(--step-0);
    }
    h5 {
      font-size: var(--step--1);
    }
    h6 {
      font-size: var(--step--2);
    }

    *,
    *:before,
    *:after {
      box-sizing: border-box;
    }

    * {
        margin: 0;
        padding: 0;
        border: 0;
        outline: 0;
        font-size: 100%;
        vertical-align: baseline;
        background: transparent;
    }

    input {
      background: var(--primary-color);
    }

    @font-face {
      font-family: "Bad Script";
      font-style: normal;
      font-weight: 400;
      font-display: swap;
      src: url("../../Fonts/Bad_Script/badscript-regular-webfont.woff2") format("woff2");
    }

    @font-face {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 100;
      font-display: swap;
      src: url("../../Fonts/Roboto/roboto-thin-webfont.woff2") format("woff2");
    }

    @font-face {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 300;
      font-display: swap;
      src: url("../../Fonts/Roboto/roboto-light-webfont.woff2") format("woff2");
    }

    @font-face {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 500;
      font-display: swap;
      src: url("../../Fonts/Roboto/roboto-medium-webfont.woff2") format("woff2");
    }

    @font-face {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 700;
      font-display: swap;
      src: url("../../Fonts/Roboto/roboto-bold-webfont.woff2") format("woff2");
    }
    @font-face {
      font-family: "Baumans";
      font-style: normal;
      font-weight: 400;
      font-display: swap;
      src: url("../../Fonts/Baumans/baumans-regular-webfont.woff2") format("woff2");
    }

    :root {
      font-family: "Roboto";
      font-weight: 300;
    }

    #root {
      height: 100vh;
      width: 100vw;
      display: grid;
      grid-template-columns: auto;
      grid-template-rows: min-content auto min-content;
    }
  }
`;

export const Typography = {
  secondary: "Baumans",
  cursive: "Bad Script",
  name: "Roboto",
};
