/* eslint-disable react/jsx-no-useless-fragment */
// import { Provider } from "react-redux";
import {
  BrowserRouter as Router,
} from "react-router-dom";

import "../constants/styles/global";
// Layout
import Layout from "./layout";

// Redux
// import { store } from "../redux/app/store";

function App() {
  return (
  // <Provider store={store}>
    <Router>
      <Layout />
    </Router>
  // </Provider>
  );
}

export default App;

/**
 * This is the app starting point for development.
 * Please edit this file when you need more initilization.
 */
